import { AssetType } from 'common-web/types/asset/AssetType';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';
import { Device } from 'types';

export function useAsset(variables?: {
  id: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['asset', variables],
    () => apiClient.get(`assets/${variables?.id}`),
    {
      enabled: !!variables?.id,
      staleTime: 0,
    }
  );
}

export function useUpdateAsset(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'assets',
    (variables: any) =>
      apiClient.put(`assets/${variables?.assetId}`, {
        ...variables,
      }),
    {
      onSuccess: (data: any, variables: any) => {
        queryClient.invalidateQueries('assets');
      },
    }
  );
}

export function useAssetDetails(variables?: {
  asset: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['asset', variables],
    () => apiClient.get(`assets/details/${variables?.asset}`),
    {
      enabled: !!variables?.asset,
      staleTime: 0,
    }
  );
}

export function useAssetTripHistory(variables?: {
  asset: number | null;
}): UseQueryResult {
  return useQuery(
    ['assetTripHistory', variables],
    () => apiClient.get(`assets/locations/trail/${variables?.asset}`),
    {
      enabled: !!variables?.asset,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export type AssetsSortByType = 'name';
export type AssetsSortOrderType = 'ASC' | 'DESC';

export function useAssets(props?: {
  searchTerm?: string;
  assetType?: string;
  page?: number;
  pageSize?: number;
  sortBy?: AssetsSortByType;
  sortOrder?: AssetsSortOrderType;
  filterBy?: any;
}): UseQueryResult {
  // @ts-expect-error
  const { searchTerm, assetType, page, sortBy, sortOrder, filterBy, pageSize } =
    props;
  return useQuery(
    [
      'assets',
      searchTerm,
      assetType,
      page,
      sortBy,
      sortOrder,
      filterBy,
      pageSize,
    ],
    () =>
      apiClient.get('assets', {
        params: {
          searchTerm,
          assetType,
          page,
          sortBy,
          sortOrder,
          pageSize,
          ...filterBy,
        },
      })
  );
}

export function useAssetsById(assetIds: number[]): UseQueryResult {
  return useQuery(['assets', assetIds], ({ signal }) =>
    apiClient.get('assets', {
      signal,
      params: { assetIds },
    })
  );
}

export function useAssetLocations(searchTerm: string = ''): UseQueryResult {
  return useQuery(
    ['assetLocations'],
    ({ signal }) =>
      apiClient.get('assets/locations', {
        signal,
        params: { searchTerm: searchTerm },
      }),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
    }
  );
}

export function useStaticAssets(searchTerm: string = ''): UseQueryResult {
  return useQuery(
    ['static-assets'],
    ({ signal }) =>
      apiClient.get('assets/static', {
        signal,
        params: { searchTerm: searchTerm },
      }),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
    }
  );
}

export function useStaticAssetPipelines({
  searchTerm = '',
  assetId = undefined,
}: {
  searchTerm?: string;
  assetId?: number;
}): UseQueryResult {
  return useQuery(
    ['static-asset-pipelines'],
    ({ signal }) =>
      apiClient.get('assets/static/pipelines', {
        signal,
        params: { searchTerm, assetId },
      }),
    {
      staleTime: assetId ? 1000 : 5 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      enabled: assetId !== null,
    }
  );
}

export function useLinkStaticAssets(): any {
  const queryClient = useQueryClient();
  return useMutation(
    ['link-static-assets'],
    ({ assetFrom, assetTo }: { assetFrom: number; assetTo: number }) => {
      return apiClient.put(`assets/static/${assetFrom}/link`, {
        upstreamAssetId: assetTo,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['static-assets']);
        queryClient.invalidateQueries(['static-asset-pipelines']);
      },
    }
  );
}

export function useUnlinkStaticAssets(): any {
  const queryClient = useQueryClient();
  return useMutation(
    ['unlink-static-assets'],
    ({ assetFrom, assetTo }: { assetFrom: number; assetTo: number }) => {
      return apiClient.put(`assets/static/${assetFrom}/unlink`, {
        upstreamAssetId: assetTo,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['static-assets']);
        queryClient.invalidateQueries(['static-asset-pipelines']);
      },
    }
  );
}

export function useAISVesselLocationsByRegions(): UseQueryResult {
  return useQuery(['ais-vessels-by-region'], ({ signal }) =>
    apiClient.get('assets/locations/ais-regions', {
      signal,
    })
  );
}

export function useAISRegionVesselDetails(variables?: {
  mmsi: number | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['ais-region-vessel', variables],
    () => apiClient.get(`assets/details/ais-regions/${variables?.mmsi}`),
    {
      enabled: !!variables?.mmsi,
    }
  );
}

export function useAssetLocationsByType(variables: any): UseQueryResult {
  return useQuery(['assetLocations', variables], () =>
    apiClient.get(`assets/locations/${variables?.type}`)
  );
}

export function useAddAsset(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'assets',
    (variables: Device) => {
      return apiClient.post('assets', {
        ...variables,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assets');
      },
    }
  );
}

export function useDeleteAsset(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'assets',
    (variables: { id: number }) => apiClient.delete(`assets/${variables.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assets');
      },
    }
  );
}

export function useDeviceTypes(): UseQueryResult {
  return useQuery('deviceTypes', () =>
    apiClient.get('definitions/devicetypes')
  );
}

export function useAssetTypes(): UseQueryResult {
  return useQuery('assetTypes', () => apiClient.get('definitions/assettypes'));
}

export type AssetStatusSortByType = 'name' | 'eta' | 'timestamp';
export type AssetStatusSortOrderType = 'ASC' | 'DESC';

export function useAssetsStatus(
  searchTerm: string | null = '',
  assetType: string = AssetType.VEHICLE,
  page: number,
  sortBy: AssetStatusSortByType,
  sortOrder: AssetStatusSortOrderType,
  filterBy: any,
  pageSize?: number
): UseQueryResult {
  return useQuery(
    [
      'report_assets_status',
      searchTerm,
      assetType,
      page,
      sortBy,
      sortOrder,
      filterBy,
      pageSize,
    ],
    ({ signal }) =>
      apiClient.get('reports/assets/status?' + filterBy, {
        signal,
        params: {
          searchTerm,
          page: page,
          type: assetType,
          sortBy,
          sortOrder,
          pageSize,
        },
      })
  );
}

export function useAssetsLog({
  page,
  reportTypes,
  filterBy,
  sortBy,
  sortOrder,
  enabled = true,
}: {
  page: number;
  reportTypes?: any;
  filterBy?: any;
  sortBy: string;
  sortOrder: string;
  enabled?: boolean;
}): UseQueryResult {
  return useQuery(
    ['report_assets_log', page, reportTypes, filterBy, sortBy, sortOrder],
    ({ signal }) =>
      apiClient.get('reports/assets/log', {
        signal,
        params: {
          page,
          reportTypes,
          ...filterBy,
          sortBy,
          sortOrder,
        },
      }),
    {
      enabled: enabled && !!filterBy.startTimestamp,
    }
  );
}

export function useExportReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_assets_log_export',
    (variables: any) =>
      apiClient.post(`reports/assets/log/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        assetIds: variables.assetIds,
        groupIds: variables.groupIds,
        reportTypes: variables.reportTypes,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_assets_log_export');
      },
    }
  );
}

export function useRequestAssetImage(): any {
  return useMutation('requestAssetImage', (variables: any) =>
    apiClient.post(`assets/${variables.assetId}/requestImage`, {
      ...variables,
    })
  );
}

export function useAssetsUtilization({
  page,
  filterBy,
}: {
  page: number;
  filterBy?: any;
}): UseQueryResult {
  return useQuery(['report_assets_utilization', page, filterBy], ({ signal }) =>
    apiClient.get('reports/assets/utilization', {
      signal,
      params: {
        page,
        ...filterBy,
      },
    })
  );
}

export function useExportUtilizationReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_assets_utilization_export',
    (variables: any) =>
      apiClient.post(`reports/assets/utilization/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        groupIds: variables.groupIds,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_assets_utilization_export');
      },
    }
  );
}

export function useToMobileLog({
  page,
  reportTypes,
  filterBy,
  enabled = true,
}: {
  page: number;
  reportTypes?: any;
  filterBy?: any;
  enabled?: boolean;
}): UseQueryResult {
  return useQuery(
    ['tm-messages', page, reportTypes, filterBy],
    ({ signal }) =>
      apiClient.get('tm-messages', {
        signal,
        params: {
          page,
          reportTypes,
          ...filterBy,
        },
      }),
    {
      enabled: enabled && !!filterBy.startTimestamp,
    }
  );
}

export type FuelReportSortByType = 'fuelEconomy';
export type FuelReportSortOrderType = 'ASC' | 'DESC';

export function useAssetsFuel({
  page,
  filterBy,
  sortBy,
  sortOrder,
}: {
  page: number;
  filterBy?: any;
  sortBy?: FuelReportSortByType;
  sortOrder?: FuelReportSortOrderType;
}): UseQueryResult {
  return useQuery(
    ['report_assets_fuel', page, filterBy, sortBy, sortOrder],
    ({ signal }) =>
      apiClient.get('reports/assets/fuel', {
        signal,
        params: {
          page,
          sortBy,
          sortOrder,
          ...filterBy,
        },
      })
  );
}

export function useExportFuelReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_assets_fuel_export',
    (variables: any) =>
      apiClient.post(`reports/assets/fuel/export`, {
        startTimestamp: variables.startTimestamp,
        endTimestamp: variables.endTimestamp,
        groupIds: variables.groupIds,
        fileType: variables.fileType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_assets_fuel_export');
      },
    }
  );
}

export function useRequestAssetLocation(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'asset_request_location',
    (variables: any) =>
      apiClient.post(`assets/${variables.assetId}/request-location`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('asset_request_location');
      },
    }
  );
}

export function useRequestReboot(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'asset_reboot',
    (variables: any) =>
      apiClient.post(`assets/${variables.assetId}/reboot`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('asset_reboot');
      },
    }
  );
}

export function useRequestIgnitionLockout(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'asset_request_ignition_lockout',
    (variables: any) =>
      apiClient.post(`assets/${variables.assetId}/ignition-lockout`, {
        ...variables,
      }),
    {
      onSuccess: (_, variables, __) => {
        queryClient.invalidateQueries('asset_request_ignition_lockout');
        queryClient.invalidateQueries(['asset', { asset: variables?.assetId }]);
      },
    }
  );
}

export function useSetVirtualOdometer(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'asset_set_virtual_odometer',
    (variables: any) =>
      apiClient.post(`assets/${variables.assetId}/virtual-odometer`, {
        ...variables,
      }),
    {
      onSuccess: (_, variables, __) => {
        queryClient.invalidateQueries('asset_set_virtual_odometer');
        queryClient.invalidateQueries(['asset', { asset: variables?.assetId }]);
      },
    }
  );
}

export function useUpdateAssetDefaultDriver(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'asset_default_driver',
    (variables: any) =>
      apiClient.put(`assets/${variables?.assetUuid}/default-driver`, {
        ...variables,
      }),
    {
      onSuccess: (data: any, variables: any) => {
        queryClient.invalidateQueries(['asset', { id: variables?.assetUuid }]);
      },
    }
  );
}

export function useAssetHistoricalMedia({
  assetId,
}: {
  assetId: number;
}): UseQueryResult {
  const PAGE = 1;
  const PAGE_SIZE = 50;
  return useQuery(
    ['asset-historical-media', assetId, PAGE, PAGE_SIZE],
    ({ signal }) =>
      apiClient.get(`assets/history/${assetId}/media`, {
        signal,
        params: {
          page: PAGE,
          pageSize: PAGE_SIZE,
        },
      }),
    {
      enabled: !!assetId,
    }
  );
}

export function useAssetStatusExportReport(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'report_assets_status_export',
    (variables: any) =>
      apiClient.post(`reports/assets/status/export`, {
        type: 'vehicle',
        fileType: variables.fileType,
        groupIds: variables.groupIds,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('report_assets_status_export');
      },
    }
  );
}
