import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PushPin from '@mui/icons-material/PushPin';
import PushPinOutlined from '@mui/icons-material/PushPinOutlined';
import SearchIcon from '@mui/icons-material/Search';
import useUIContext from 'context/UIContext';
import ActionMenu from '../ActionMenu/index';
import useActionMenuContext from 'context/ActionMenuContext';
import { useLocation } from 'react-router-dom';
import { ChevronLeftTwoTone, ChevronRightTwoTone } from '@mui/icons-material';

const drawerWidthOpen = 400;
const drawerWidthClosed = 65;
const useStyles: any = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: 'rgba(27, 28, 30, 0.75)',
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
});

const openedMixin = (theme: any, isMobile: boolean) => ({
  width: isMobile ? '100%' : drawerWidthOpen,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: any, isMobile: boolean) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: isMobile ? 0 : drawerWidthClosed,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isMobile',
})<any>(({ theme, open, isMobile }: any) => ({
  width: isMobile ? 'auto !important' : drawerWidthOpen,
  height: isMobile ? '50px' : '100vh',
  ...(isMobile && {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  }),
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'absolute',
  ...(open && {
    ...openedMixin(theme, isMobile),
    '& .MuiDrawer-paper': openedMixin(theme, isMobile),
  }),
  ...(!open && {
    ...closedMixin(theme, isMobile),
    '& .MuiDrawer-paper': closedMixin(theme, isMobile),
  }),
}));

export default function MiniDrawer({ preventKeyPressDefault }: any) {
  const drawerRef = useRef(null);
  const {
    openRightSidebar,
    rightSideBarAlwaysOpen,
    closeRightSidebar,
    rightSideBarOpen,
    rightSideBarFixed,
    fixRightSidebar,
    direction,
    isMobile,
  } = useUIContext();
  const { reset } = useActionMenuContext();
  const [isClosing, setClosing] = React.useState(false);
  const classes = useStyles();
  const [delayHandler, setDelayHandler] = React.useState<any>(null);
  const location = useLocation();
  const touchStartRef = useRef(null);
  const touchEndRef = useRef(null);

  const minSwipeDistance = 150;

  const onTouchStart = (e: any) => {
    touchEndRef.current = null;
    touchStartRef.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: any) => {
    touchEndRef.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStartRef.current || !touchEndRef.current) return;
    const distance = touchStartRef.current - touchEndRef.current;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe) {
      close();
    }
  };

  React.useEffect(() => {
    if (!rightSideBarFixed) {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
      }, 250);
    }
  }, [rightSideBarFixed]);

  const open = () => {
    if (isClosing) return;
    openRightSidebar();
  };

  const close = () => {
    closeRightSidebar();
    reset();
  };

  const handleMouseEnter = (event: any) => {
    if (rightSideBarOpen) {
      clearTimeout(delayHandler);
    }

    if (!rightSideBarOpen && !rightSideBarFixed && !rightSideBarAlwaysOpen) {
      setDelayHandler(
        setTimeout(() => {
          open();
        }, 400)
      );
    }
  };

  const handleMouseLeave = () => {
    if (!rightSideBarOpen) {
      clearTimeout(delayHandler);
    }

    if (rightSideBarOpen && !rightSideBarFixed && !rightSideBarAlwaysOpen) {
      setDelayHandler(
        setTimeout(() => {
          close();
        }, 200)
      );
    }
  };

  return (
    <>
      <Box
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        sx={{
          display: 'flex',
          ...(isMobile && {
            position: 'fixed',
            bottom: 0,
            height: '8dvh',
            overflow: 'hidden',
            zIndex: 1004,
          }),
          boxSizing: 'border-box',
        }}
        style={{ width: '100%', backgroundColor: '#3F4349' }}>
        {isMobile && (
          <Box
            onClick={() => (rightSideBarOpen ? close() : open())}
            sx={{
              display: 'flex',
              width: '100%',
              textAlign: 'center',
              zIndex: 1003,
              height: '100%',
            }}
            justifyContent='center'
            alignItems='center'>
            {rightSideBarOpen ? (
              <ChevronRightTwoTone sx={{ fontSize: 32, opacity: 0.8 }} />
            ) : (
              <ChevronLeftTwoTone sx={{ fontSize: 32, opacity: 0.8 }} />
            )}
          </Box>
        )}
        <CssBaseline />
        <Drawer
          ref={drawerRef}
          anchor={'rtl' === direction ? 'left' : 'right'}
          classes={{ paper: classes.paper }}
          variant='permanent'
          open={rightSideBarOpen}
          isMobile={isMobile}
          style={{
            height: isMobile ? 'calc( 100dvh - 6dvh )' : '100vh',
            // backgroundColor: '#3F4349',
            backgroundColor: '#242628',
            ...('rtl' === direction ? { left: 0 } : { right: 0 }),
            ...(!rightSideBarOpen &&
              isMobile && { position: 'absolute', bottom: 0, height: '50px' }),
            boxSizing: 'border-box',
          }}
          sx={{
            '& .MuiDrawer-paper': {
              ...(isMobile && {
                height: isMobile ? 'calc( 100dvh - 6dvh )' : '100vh',
                boxSizing: 'border-box',
              }),
            },
          }}
          SlideProps={{
            appear: false,
            direction: 'rtl' === direction ? 'left' : 'right',
            in: false,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {'/' === location.pathname && (
            <DrawerHeader
              sx={{
                height: rightSideBarOpen ? 'auto' : 72,
                border: 0,
                ...(rightSideBarOpen && { backgroundColor: '#242628' }),
              }}>
              {!isMobile && !rightSideBarAlwaysOpen && (
                <ListItem
                  key={'collapser'}
                  onClick={() =>
                    isMobile
                      ? null
                      : rightSideBarOpen && rightSideBarFixed
                      ? fixRightSidebar(false)
                      : fixRightSidebar(true)
                  }
                  disablePadding
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      justifyContent: rightSideBarOpen ? 'initial' : 'center',
                      px: 1.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        ...('rtl' === direction
                          ? {
                              ml: rightSideBarOpen ? 3 : 'auto',
                            }
                          : {
                              mr: rightSideBarOpen ? 3 : 'auto',
                            }),
                        justifyContent: 'center',
                      }}>
                      {!rightSideBarOpen && (
                        <SearchIcon color='secondary' sx={{ opacity: 0.75 }} />
                      )}
                      {!isMobile && rightSideBarOpen && rightSideBarFixed && (
                        <PushPin color='secondary' sx={{ height: '1rem' }} />
                      )}
                      {!isMobile && rightSideBarOpen && !rightSideBarFixed && (
                        <PushPinOutlined
                          color='secondary'
                          sx={{ opacity: 0.5, height: '1rem' }}
                        />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )}
            </DrawerHeader>
          )}
          {!isMobile && rightSideBarOpen && <Divider />}
          <ActionMenu preventKeyPressDefault={preventKeyPressDefault} />
        </Drawer>
      </Box>
      <div
        className='right-side-bar-hack'
        style={{
          height: '100vh',
          width: isMobile ? '100%' : 400,
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          ...('rtl' === direction ? { left: 0 } : { right: 0 }),
          opacity: 0,
          zIndex: -9999999,
        }}
      />
    </>
  );
}
