import { RefreshRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import GenericFilterDumb from 'components/Filters/GenericFilterDumb';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import { useAssetsFilterWithSearch } from 'hooks/filters/useFilters';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateFilter, GroupFilter } from '../../../components/Filters';
import ReportTypeFilter from '../../../components/Filters/ReportTypeFilter';

const AssetLogFilters = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ py: 0.5, mt: 2 }}>
      <Grid item xs={2}>
        <DateFilter {...props} sx={{ mr: 1 }} />
      </Grid>
      <Grid item xs={3}>
        <GenericFilterDumb
          {...props}
          multiple={true}
          size='small'
          limitTags={0}
          filterName='asset'
          label='Assets'
          keyProperty='assetId'
          labelProperty='name'
          useHookForData={useAssetsFilterWithSearch}
        />
      </Grid>
      <Grid item xs={3}>
        <GroupFilter {...props} multiple={true} size='small' limitTags={0} />
      </Grid>
      <Grid item xs={3}>
        <ReportTypeFilter {...props} sx={{ ml: 1 }} multiple={true} />
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
        <Grid item xs={4}>
          <CreateReportButton type={ScheduledReportType.ASSET_LOG} />
        </Grid>
        {props?.onRefreshClicked && (
          <Grid item xs={4}>
            <Tooltip title={t('Refresh data')} arrow>
              <IconButton onClick={props?.onRefreshClicked}>
                <RefreshRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={4}>
          <props.exportComponent filters={props.filters} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const AssetLogToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <AssetLogFilters {...props} />
    </WithUrlFilters>
  );
};

export default AssetLogToolbarWithUrlFilters;
