import { Box, Chip, Divider, Grid } from '@mui/material';
import OrbcommLogo from 'assets/ORBC.png';
import InmarsatLogo from 'assets/inmarsat.png';
import SnapgisLogo from 'assets/original-icon-simplified.svg';
import TwilioLogo from 'assets/twilio.svg';
import SearchInputForm from 'components/ActionMenu/modules/GlobalSearch/SearchInputForm';
import BoxedContainer from 'components/BoxedContainer';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import { Formik } from 'formik';
import { useIntegrations } from 'hooks/integrations/useIntegrations';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalSearchFormSchema from 'schemas/GlobalSearch';
import IntegrationCard from './IntegrationCard';
import integrationList from './integrations.json';

const providerLogos = {
  Snapgis: {
    image: SnapgisLogo,
    background: 'white',
  },
  ORBCOMM: {
    image: OrbcommLogo,
    background: 'white',
  },
  Inmarsat: {
    image: InmarsatLogo,
    background: 'white',
  },
  Twilio: {
    image: TwilioLogo,
    background: 'white',
  },
} as any;
const typesFromIntegrationList = integrationList
  .map((integration: any) => integration.type)
  .filter((value, index, array) => array.indexOf(value) === index);

const providersFromIntegrationList = integrationList
  .map((integration: any) => integration.provider)
  .filter((value, index, array) => array.indexOf(value) === index);

export const Integrations = () => {
  const [visibleIntegrations, setVisibleIntegrations] =
    useState<Array<any>>(integrationList);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<string | null>(null);
  const { t } = useTranslation();
  const { setActive } = useActionMenuContext();
  const { openRightSidebar, fixRightSidebar, closeRightSidebar } =
    useUIContext();
  const { data } = useIntegrations() as any;

  useEffect(() => {
    return () => {
      fixRightSidebar(false);
      closeRightSidebar();
    };
  }, []);

  const setIntegrationVisibility = () => {
    if (!filter && searchTerm.length < 1) {
      return setVisibleIntegrations(integrationList);
    }
    setVisibleIntegrations(
      structuredClone(integrationList).filter((i: any) => {
        const filterCondition = () =>
          i.provider === filter ||
          i.type === filter ||
          i.name.includes(filter) ||
          i.tags.includes(filter);
        const searchCondition = () =>
          i.provider.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          i.tags.includes(searchTerm.toLowerCase());

        if (filter && searchTerm) return filterCondition() && searchCondition();
        if (filter) return filterCondition();
        if (searchTerm) return searchCondition();
      })
    );
  };

  useEffect(() => {
    setIntegrationVisibility();
  }, [filter]);

  useEffect(() => {
    setIntegrationVisibility();
  }, [searchTerm]);

  return (
    <BoxedContainer>
      <Box sx={{ mb: 4 }}>
        <Formik
          validateOnMount={true}
          initialValues={{
            search: '',
          }}
          validationSchema={GlobalSearchFormSchema(t)}
          validateOnChange={false}
          validateOnBlur={false}
          onReset={() => {
            setSearchTerm('');
          }}
          onSubmit={(values: any, { setSubmitting }: any) => {
            setSearchTerm(values.search);
            setSubmitting(false);
          }}>
          {(props: any) => (
            <SearchInputForm
              {...props}
              isLoading={false}
              setSearchTerm={setSearchTerm}
              placeholder={'Search for an Integration'}
            />
          )}
        </Formik>
        <Box sx={{ mt: 1 }}>
          {typesFromIntegrationList.map((item: any) => {
            return (
              <Chip
                sx={{ mr: 1 }}
                key={item}
                label={item.charAt(0).toUpperCase() + item.slice(1)}
                variant={filter === item ? 'filled' : 'outlined'}
                onClick={() => {
                  setFilter(filter === item ? null : item);
                }}
              />
            );
          })}
          {providersFromIntegrationList.map((item: any) => {
            return (
              <Chip
                sx={{ mr: 1 }}
                key={item}
                label={item}
                variant={filter === item ? 'filled' : 'outlined'}
                onClick={() => {
                  setFilter(filter === item ? null : item);
                }}
              />
            );
          })}
        </Box>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Grid container spacing={6} sx={{ py: 0.5 }}>
          {visibleIntegrations &&
            visibleIntegrations.map((integration: any) => {
              if (!integration) return;
              const configured = data?.find(
                (i: any) => i.type === integration.canonical
              );
              return (
                <Grid item xs={4} key={integration?.name}>
                  <IntegrationCard
                    Image={providerLogos[integration?.provider]?.image}
                    configured={configured}
                    error={(() => {
                      const integrationItem = data?.find(
                        (i: any) => i.type === integration.canonical
                      );
                      if (integrationItem) return integrationItem?.error;
                      return null;
                    })()}
                    title={integration?.name}
                    description={integration?.description}
                    imgBackground={
                      !!providerLogos[integration?.provider]?.background &&
                      providerLogos[integration?.provider]?.background
                    }
                    onClick={() => {
                      setActive(ActionMenuModuleTypes.AddIntegration, {
                        name: integration.name,
                        canonical: integration.canonical,
                        configured: configured,
                      });
                      openRightSidebar();
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </BoxedContainer>
  );
};

export default Integrations;
