import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      queryStringParams: { v: '0.0.3' },
    },
    lng: 'en',
    // debug: ['local', 'development'].includes(process.env.NODE_ENV),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'languageOnly',
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
  });

export default i18n;
