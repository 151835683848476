import { ShortHandLayer } from './types';

export default ({ id, source, color, size, opacity, dash }: ShortHandLayer) => {
  return {
    id,
    type: 'line',
    source,
    layout: {
      'line-join': 'round',
      'line-cap': 'butt',
    },
    paint: {
      'line-color': color || '#00a8ff',
      'line-width': size || 2,
      'line-opacity': opacity || 1,
      'line-dasharray': dash || [1, 0],
    },
  };
};
