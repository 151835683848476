import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  // eslint-disable-next-line import/named
  GridCellParams,
  // eslint-disable-next-line import/named
  GridColDef,
  // eslint-disable-next-line import/named
  GridEventListener,
  // eslint-disable-next-line import/named
  MuiEvent,
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import deviceTypes from 'common-web/definitions/deviceTypes.json';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useExportTripsReport, useTrips } from 'hooks/reports/useTrips';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReportWrapper from '../ReportWrapper';
import TripsToolbarWithUrlFilters from './TripFilters';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
// eslint-disable-next-line import/named

function getDeviceFullName(id: any) {
  if (deviceTypes) {
    var config = deviceTypes.deviceTypes.filter((d: any) => d.id === id);
    return `${config?.[0]?.manufacturer} ${config?.[0]?.model}`;
  }
}

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

function getColumns({
  navigate,
  hideColumnIndexes,
  assetType,
}: any): GridColDef[] {
  return [
    //@ts-ignore
    {
      field: 'name',
      headerName: 'Asset',
      ...commonColDefOptions,
      hide: hideColumnIndexes.includes(0),
      flex: 2,
      renderCell: (r: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Box sx={{ fontWeight: 600 }}>{r.row.name}</Box>
            <Box
              sx={{
                fontSize: '.7rem',
                color: '#ccc',
                mt: 0.5,
              }}>
              {getDeviceFullName(r.row?.deviceType)}
            </Box>
          </Box>
        );
      },
    }, //@ts-ignore
    {
      field: 'endTimestamp',
      headerName: 'Ended',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        let textVal = '';
        const diffMins = moment
          .duration(moment().diff(r?.row?.endTimestamp))
          .asMinutes();
        if (diffMins < 60) {
          textVal = `${diffMins.toFixed(0)} minutes ago`;
        }
        textVal = moment(r?.row?.endTimestamp).fromNow();

        return r?.row?.endTimestamp ? (
          <Tooltip
            arrow={true}
            title={moment(r?.row?.endTimestamp).format('YYYY-MM-DD HH:mm:ss')}>
            <Box>{textVal}</Box>
          </Tooltip>
        ) : (
          ''
        );
      },
    }, //@ts-ignore
    {
      field: 'driverName',
      headerName: 'Driver',
      hide: hideColumnIndexes.includes(2),
      ...commonColDefOptions,
      flex: 1.5,
    }, //@ts-ignore
    {
      field: 'startTimestamp',
      headerName: 'Duration',
      ...commonColDefOptions,
      flex: 0.7,
      renderCell: (r: any) => {
        const days = moment(r?.row?.endTimestamp).diff(
          moment(r?.row?.startTimestamp),
          'days'
        );
        const hours =
          (moment(r?.row?.endTimestamp).diff(
            moment(r?.row?.startTimestamp),
            'hours'
          ) %
            24) +
          '';
        const minutes =
          (moment(r?.row?.endTimestamp).diff(
            moment(r?.row?.startTimestamp),
            'minutes'
          ) %
            60) +
          '';
        return `${days > 0 ? days + 'd' : ''} ${hours}h ${minutes.padStart(
          2,
          '0'
        )}m`;
      },
    }, //@ts-ignore
    {
      field: 'distance',
      headerName: 'Distance',
      ...commonColDefOptions,
      flex: 1,
      valueGetter: ({ row }) => {
        const distance = (() => {
          if (assetType === 'vehicle') {
            return row?.distance;
          }
          if (['vessel_ais', 'vessel'].includes(assetType)) {
            return (row?.distance / 1.852).toFixed(1);
          }
        })();
        if (['vessel_ais', 'vessel'].includes(assetType)) {
          return `${distance}  NM`;
        }
        return `${distance}  km`;
      },
    }, //@ts-ignore
    {
      field: 'startFormattedAddress',
      headerName: 'From - To',
      ...commonColDefOptions,
      flex: 3,
      renderCell: (r: any) => {
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '.8rem',
              }}>
              <Tooltip arrow={true} title={r?.row?.startFormattedAddress}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '.8rem',
                    alignItems: 'center',
                    mb: 0.5,
                  }}>
                  <OutlinedFlagIcon
                    sx={{
                      fontSize: '.9rem',
                      mr: 1,
                    }}></OutlinedFlagIcon>
                  {r?.row?.startFormattedAddress ??
                    `
                      ${r?.row?.startPosition.lat.toFixed(7)}, 
                      ${r?.row?.startPosition.lon.toFixed(7)}
                    `}
                </Box>
              </Tooltip>
              <Tooltip arrow={true} title={r?.row?.endFormattedAddress}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '.8rem',
                    alignItems: 'center',
                  }}>
                  <FlagIcon
                    sx={{
                      fontSize: '.9rem',
                      mr: 1,
                    }}></FlagIcon>
                  {r?.row?.endFormattedAddress ??
                    `
                      ${r?.row?.endPosition.lat.toFixed(7)}, 
                      ${r?.row?.endPosition.lon.toFixed(7)}
                    `}
                </Box>
              </Tooltip>
            </Box>
          </>
        );
      },
    }, //@ts-ignore
  ];
}
const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root': {
      cursor: 'pointer',
    },
  },
});

const Trips = ({
  assetType = 'vehicle',
  showFilters = true,
  hideColumnIndexes = [],
  boxed = true,
  assetIds = [],
  driverId,
  pageSize = 10,
  showAllTrips = false,
  startTimestamp = null,
}: any) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const { data, isLoading } = useTrips({
    page: page + 1,
    pageSize,
    assetType,
    filterBy: showFilters
      ? searchParams
      : {
          startTimestamp:
            startTimestamp ??
            moment()
              .startOf('day')
              .subtract(7, 'days')
              .format('YYYY-MM-DD HH:mm:ss'),
          endTimestamp: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          assetIds,
          driverId,
          showAllTrips,
        },
  }) as any;
  const { reset } = useActionMenuContext();
  const { canRead } = usePermissions();
  const [rowCountState, setRowCountState] = React.useState(0);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  React.useEffect(() => {
    return () => reset();
  }, []);

  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    navigate(`/reports/assets/trips/${params.row?.tripId}`);
  };

  const classes = useStyles();

  const ContainerTag: any = boxed ? ReportWrapper : Box;

  return (
    <ContainerTag>
      {showFilters && canRead('ASSET') && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 1,
          }}>
          <Box sx={{ width: '100%' }}>
            <TripsToolbarWithUrlFilters
              assetType={assetType}
              exportComponent={ExportTrips}
            />
          </Box>
        </Box>
      )}
      <DataGrid
        disableSelectionOnClick={true}
        onCellClick={(
          params: GridCellParams,
          event: MuiEvent<React.MouseEvent>
        ) => {
          event.defaultMuiPrevented = true;
        }}
        className={classes.root}
        onRowClick={handleEvent}
        rows={data?.trips || []}
        getRowId={row => row.tripId}
        columns={getColumns({
          navigate,
          hideColumnIndexes,
          assetType,
        })}
        pageSize={pageSize || 10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={52}
        rowCount={rowCountState}
        onPageChange={newPage => {
          setPage(newPage);
        }}
        paginationMode='server'
        pagination
      />
    </ContainerTag>
  );
};

const ExportTrips = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportTripsReport();

  const handleExport = (fileType: any) => {
    handleClose();
    exportReport({
      ...filters,
      fileType,
    });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip arrow={true} title={t('reports.export_report')}>
        <IconButton disabled={isExporting} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

export default Trips;
