import { RefreshOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import SearchInputForm from 'components/ActionMenu/modules/GlobalSearch/SearchInputForm';
import { GroupFilter } from 'components/Filters';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import { Formik } from 'formik';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalSearchFormSchema from 'schemas/GlobalSearch';

const AssetStatusFilters = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ mb: 2, ...props.gridSx }}>
      <Grid item xs={3}>
        <Formik
          validateOnMount={true}
          initialValues={{
            search: '',
          }}
          validationSchema={GlobalSearchFormSchema(t)}
          validateOnChange={false}
          validateOnBlur={false}
          onReset={() => {
            props.setSearchTerm('');
          }}
          onSubmit={(values: any, { setSubmitting }: any) => {
            props.setSearchTerm(values.search);
            setSubmitting(false);
          }}>
          {(props: any) => (
            <SearchInputForm
              {...props}
              isLoading={props.isLoading}
              setSearchTerm={props.setSearchTerm}
              placeholder={t('reports.status.search_placeholder')}
            />
          )}
        </Formik>
      </Grid>
      <Grid item xs={3}>
        <GroupFilter {...props} multiple={true} size='small' limitTags={0} />
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <CreateReportButton type={ScheduledReportType.ASSET_STATUS} />
          <props.exportComponent filters={props.filters} />
          <Tooltip arrow={true} title={t('reload_data')}>
            <IconButton size='small' onClick={() => props.refetch()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

const AssetStatusToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <AssetStatusFilters {...props} />
    </WithUrlFilters>
  );
};

export default AssetStatusToolbarWithUrlFilters;
