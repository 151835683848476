import React, { useContext, useReducer } from 'react';

const drawerWidthOpen = 400;
const drawerWidthClosed = 65;

interface Action {
  type: string;
  payload: any;
}

export const UIContextProps = {
  rightSideBarOpen: false,
  rightSideBarAlwaysOpen: false,
  rightSideBarWidth: 0,
  rightSideBarFixed: false,
  leftSideBarOpen: false,
  leftSideBarWidth: 0,
  leftSideBarFixed: false,
  flashMessage: {},
  aiDialogOpen: false,
  helpCenterOpen: false,
};

export enum DIR {
  LTR = 'ltr',
  RTL = 'rtl',
}

export type UIContextProps = {
  rightSideBarOpen: boolean;
  rightSideBarAlwaysOpen: boolean;
  rightSideBarWidth: number;
  rightSideBarFixed: boolean;
  leftSideBarOpen: boolean;
  leftSideBarWidth: number;
  leftSideBarFixed: boolean;
  openRightSidebar: Function;
  fixRightSidebar: Function;
  closeRightSidebar: Function;
  openLeftSidebar: Function;
  fixLeftSidebar: Function;
  closeLeftSidebar: Function;
  setFlashMessage: Function;
  flashMessage: object;
  openAiDialog: Function;
  closeAiDialog: Function;
  aiDialogOpen: boolean;
  openHelpCenter: Function;
  closeHelpCenter: Function;
  helpCenterOpen: boolean;
  setDirection: any;
  direction: DIR;
  isMobile: boolean;
  setIsMobile: Function;
};

enum DRAWER_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

const UIContext = React.createContext(UIContextProps);

export const UIContextProvider = ({ children, defaultState }: any) => {
  const { rightSideBarAlwaysOpen, direction, isMobile } = defaultState;
  if (direction) {
    document.documentElement.dir = direction;
  }
  const [state, dispatch] = useReducer(reducer, {
    ...UIContextProps,
    rightSideBarAlwaysOpen,
    direction,
    isMobile,
  });

  React.useEffect(() => {
    dispatch({
      type: 'setRightSideBarAlwaysOpen',
      payload: !!rightSideBarAlwaysOpen,
    });
    if (rightSideBarAlwaysOpen) {
      openRightSidebar();
    }
    fixRightSidebar(false);
  }, []);

  React.useEffect(() => {
    dispatch({
      type: 'setRightSideBarAlwaysOpen',
      payload: !!rightSideBarAlwaysOpen,
    });
    if (rightSideBarAlwaysOpen) {
      openRightSidebar();
    }
  }, [rightSideBarAlwaysOpen]);

  const openRightSidebar = () => {
    dispatch({
      type: 'openRightSidebar',
      payload: drawerWidthOpen,
    });
  };

  const fixRightSidebar = (value: boolean) => {
    dispatch({
      type: 'fixRightSidebar',
      payload: value,
    });
  };

  const closeRightSidebar = () => {
    dispatch({
      type: 'closeRightSidebar',
      payload: drawerWidthClosed,
    });
  };

  const openLeftSidebar = (width: number | string) => {
    dispatch({
      type: 'openLeftSidebar',
      payload: width,
    });
  };

  const fixLeftSidebar = (value: boolean) => {
    dispatch({
      type: 'fixLeftSidebar',
      payload: value,
    });
  };

  const closeLeftSidebar = (width: number | string) => {
    dispatch({
      type: 'closeLeftSidebar',
      payload: width,
    });
  };

  const setFlashMessage = (data: any) => {
    dispatch({
      type: 'setFlashMessage',
      payload: data,
    });
  };

  const setAiDialogOpen = (open: boolean) => {
    dispatch({
      type: 'setAiDialogOpen',
      payload: open,
    });
  };

  const setHelpCenterOpen = (open: boolean) => {
    dispatch({
      type: 'setHelpCenterOpen',
      payload: open,
    });
  };

  const setDirection = (direction: 'ltr' | 'rtl') => {
    dispatch({
      type: 'setDirection',
      payload: direction,
    });
  };

  const setIsMobile = (isMobile: boolean) => {
    dispatch({
      type: 'setIsMobile',
      payload: isMobile,
    });
  };

  const value = {
    rightSideBarOpen: state.rightSideBarOpen,
    rightSideBarAlwaysOpen: state.rightSideBarAlwaysOpen,
    rightSideBarWidth: state.rightSideBarWidth,
    rightSideBarFixed: state.rightSideBarFixed,
    leftSideBarOpen: state.leftSideBarOpen,
    leftSideBarWidth: state.leftSideBarWidth,
    leftSideBarFixed: state.leftSideBarFixed,
    flashMessage: state.flashMessage,
    aiDialogOpen: state.aiDialogOpen,
    helpCenterOpen: state.helpCenterOpen,
    direction: state.direction,
    isMobile: state.isMobile,
    openRightSidebar: React.useCallback(() => openRightSidebar(), []),
    fixRightSidebar: React.useCallback(
      (value: boolean) => fixRightSidebar(value),
      []
    ),
    closeRightSidebar: React.useCallback(() => closeRightSidebar(), []),
    openLeftSidebar: React.useCallback(
      (width: any) => openLeftSidebar(width),
      []
    ),
    fixLeftSidebar: React.useCallback(
      (value: boolean) => fixLeftSidebar(value),
      []
    ),
    closeLeftSidebar: React.useCallback(
      (width: any) => closeLeftSidebar(width),
      []
    ),
    setFlashMessage: React.useCallback(
      (data: any) => setFlashMessage(data),
      []
    ),
    openAiDialog: React.useCallback(() => setAiDialogOpen(true), []),
    closeAiDialog: React.useCallback(() => setAiDialogOpen(false), []),
    openHelpCenter: React.useCallback(() => setHelpCenterOpen(true), []),
    closeHelpCenter: React.useCallback(() => setHelpCenterOpen(false), []),
    setDirection: React.useCallback(
      (direction: 'ltr' | 'rtl') => setDirection(direction),
      []
    ),
    setIsMobile: React.useCallback(
      (isMobile: boolean) => setIsMobile(isMobile),
      []
    ),
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

const actions: any = {
  openRightSidebar: (state: any, action: Action) => {
    return {
      rightSideBarOpen: true,
      rightSideBarWidth: action.payload,
    };
  },
  setRightSideBarAlwaysOpen: (state: any, action: Action) => {
    return {
      rightSideBarAlwaysOpen: action.payload,
    };
  },
  closeRightSidebar: (state: any, action: Action) => {
    return {
      rightSideBarOpen: false,
      rightSideBarWidth: action.payload,
      rightSideBarFixed: false,
    };
  },
  fixRightSidebar: (state: any, action: Action) => {
    return {
      rightSideBarFixed: action.payload,
    };
  },
  openLeftSidebar: (state: any, action: Action) => {
    return {
      leftSideBarOpen: true,
      leftSideBarWidth: action.payload,
    };
  },
  closeLeftSidebar: (state: any, action: Action) => {
    return {
      leftSideBarOpen: false,
      leftSideBarWidth: action.payload,
    };
  },
  fixLeftSidebar: (state: any, action: Action) => {
    return {
      leftSideBarFixed: action.payload,
    };
  },
  setFlashMessage: (state: any, action: Action) => {
    return {
      flashMessage: action.payload,
    };
  },
  setAiDialogOpen: (state: any, action: Action) => {
    return {
      aiDialogOpen: action.payload,
    };
  },
  setHelpCenterOpen: (state: any, action: Action) => {
    return {
      helpCenterOpen: action.payload,
    };
  },
  setDirection: (state: any, action: Action) => {
    return {
      direction: action.payload,
    };
  },
  setIsMobile: (state: any, action: Action) => {
    return {
      isMobile: action.payload,
    };
  },
};

export const reducer = (state: any, action: Action) => {
  if (Object.keys(actions).includes(action.type)) {
    const res = actions[action.type](state, action);
    return {
      ...state,
      ...res,
    };
  }
  throw new Error(
    `No case for type ${action.type} found in UIContext Reducer.`
  );
};

const useUIContext = () => {
  const context = useContext(UIContext) as UIContextProps;

  if (context === undefined) {
    throw new Error('useUIContext must be used within UIContext');
  }

  return context;
};

export default useUIContext;
