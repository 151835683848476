import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'services/axios';

export function useDashboardWidget(variables?: {
  assetType: string;
  widgetType: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
  groupIds?: number[];
  assetId?: number;
}): UseQueryResult {
  return useQuery(['dashboard', variables], () =>
    apiClient.get(`dashboard/${variables?.assetType}/widget`, {
      params: {
        widgetType: variables?.widgetType,
        startTimestamp: variables?.startTimestamp,
        endTimestamp: variables?.endTimestamp,
        groupIds: variables?.groupIds,
        assetId: variables?.assetId,
      },
    }), {
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
    }
  );
}
