import { useQuery } from 'react-query';

export function useMapMatching(
  accessToken: string,
  coordinates: number[][],
  shouldMapMatch: boolean
) {
  return useQuery<any>(
    ['mapMatching', coordinates],
    async () => {
      const response = await fetch(
        `https://api.mapbox.com/matching/v5/mapbox/driving/${coordinates
          .map(coord => coord.join(','))
          .join(';')}?geometries=geojson&access_token=${accessToken}`
      );
      return response.json();
    },
    {
      enabled: !!shouldMapMatch,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}

export function useDirectionData(
  accessToken: string,
  coordinates: number[][],
  shouldMapMatch: boolean
) {
  return useQuery<any>(
    ['mapMatching', coordinates],
    async () => {
      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates
          .map(coord => coord.join(','))
          .join(';')}?geometries=geojson&access_token=${accessToken}`
      );
      return response.json();
    },
    {
      enabled: !!shouldMapMatch,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}
