import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'any',
      ['==', ['get', 'reportType'], 'speeding_start'],
      ['==', ['get', 'reportType'], 'panic_start'],
      ['==', ['get', 'reportType'], 'panic_event'],
      ['==', ['get', 'reportType'], 'harsh_acceleration'],
      ['==', ['get', 'reportType'], 'harsh_brake'],
      ['==', ['get', 'reportType'], 'harsh_turn'],
      ['==', ['get', 'reportType'], 'accident_detected'],
      ['==', ['get', 'reportType'], 'excess_idling_start'],
      ['==', ['get', 'reportType'], 'antenna_disconnected'],
      ['==', ['get', 'reportType'], 'gps_jamming_start'],
      ['==', ['get', 'reportType'], 'lockout_engaged'],
    ],
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'reportType'], 'panic_start'],
        'trip-panic-indicator-image',
        ['==', ['get', 'reportType'], 'panic_event'],
        'trip-panic-indicator-image',
        ['==', ['get', 'reportType'], 'accident_detected'],
        'trip-panic-indicator-image',
        'trip-event-indicator-image',
      ],
      'icon-rotation-alignment': 'map',
      'icon-anchor': 'center',
      'icon-offset': [0, 0],
      'icon-allow-overlap': true,
      'icon-size': 0.2,
    },
  };
};
