import { ShortHandLayer } from './types';

export default ({ id, source }: ShortHandLayer) => {
  return {
    id,
    type: 'symbol',
    source,
    filter: [
      'all',
      ['has', 'assetType'],
      ['==', ['get', 'assetType'], 'vessel'],
      ['!', ['has', 'point_count']],
    ],
    layout: {
      'icon-image': [
        'match',
        ['get', 'activeAlert'],
        'panic',
        'vessel_panic',
        'vessel',
      ],
      'icon-rotate': ['get', 'heading'],
      "icon-rotation-alignment": "map",
      'icon-anchor': 'center',
      'icon-offset': [0, 0],
      'icon-allow-overlap': true,
      'icon-size': 0.32,
    },
  };
};
