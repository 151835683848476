import React from 'react';
import {
  useAssetLocationsByType,
  useDashboardWidget,
  useLocalStorage,
} from 'hooks';
import Map from 'components/Map/AltMap';
import bbox from '@turf/bbox';
import {
  AssetClusters,
  AssetClustersCount,
  AssetLocationSource,
  DeviceHeading,
  DevicePoint,
  LAYERS,
  SOURCES,
  VesselAisLocation,
  VesselLocation,
} from 'components/Map';
import HeadingImage from 'assets/heading-mini.svg';
import HeadingImagePanic from 'assets/heading-mini-panic.svg';
import VesselAISImage from 'assets/vessel-ais-mini.svg';
import VesselImage from 'assets/vessel-mini.svg';
import bboxPolygon from '@turf/bbox-polygon';
import buffer from '@turf/buffer';
import area from '@turf/area';
import { Skeleton } from '@mui/material';

const assetLocations: any = DevicePoint({
  id: LAYERS.ASSET_POINTS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetHeadings: any = DeviceHeading({
  id: LAYERS.ASSET_HEADINGS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetsClusters: any = AssetClusters({
  id: LAYERS.ASSET_CLUSTERS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetsClustersCount: any = AssetClustersCount({
  id: LAYERS.ASSET_CLUSTERS_COUNT,
  source: SOURCES.ASSET_LOCATIONS,
});
const vesselAISLocations: any = VesselAisLocation({
  id: LAYERS.VESSEL_LOCATIONS_AIS,
  source: SOURCES.ASSET_LOCATIONS,
});
const vesselLocations: any = VesselLocation({
  id: LAYERS.VESSEL_LOCATIONS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetHeadingsImage: any = {
  id: 'heading',
  src: HeadingImage,
};
const assetHeadingsImagePanic: any = {
  id: 'heading_panic',
  src: HeadingImagePanic,
};
const vesselAISImage: any = {
  id: 'vessel_ais',
  src: VesselAISImage,
  type: 'svg',
};
const vesselImage: any = {
  id: 'vessel',
  src: VesselImage,
  type: 'svg',
};

const getLayersForAssetType = (assetType: string) => {
  if ('vessel_ais' === assetType) {
    return [vesselAISLocations];
  }
  if ('vehicle' === assetType) {
    return [assetLocations, assetHeadings, assetsClusters, assetsClustersCount];
  }
  if ('vessel' === assetType) {
    return [vesselLocations];
  }
};

const getImagesForAssetType = (assetType: string) => {
  if ('vessel_ais' === assetType) {
    return [vesselAISImage];
  }
  if ('vehicle' === assetType) {
    return [assetHeadingsImage, assetHeadingsImagePanic];
  }
  if ('vessel' === assetType) {
    return [vesselImage];
  }
};

export const MapChart = ({ assetType, widgetType }: any) => {
  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
  }) as any;
  const [assetLocationsDataSource, setAssetLocationsDataSource] =
    React.useState<any>();
  const [mapBbox, setMapBbox] = React.useState<any>();
  const layers = getLayersForAssetType(assetType);
  const images = getImagesForAssetType(assetType);
  const [storedMapStyle, storeMapStyle] = useLocalStorage('mapStyle', '');

  const computeBoundingBox = React.useCallback(() => {
    const pos = data?.map((row: any) => [
      row?.position?.lon,
      row?.position?.lat,
    ]);
    const geojson = computeAssetLocationSource();
    const tmpBbox = bbox(geojson?.data?.data);
    const tmpBboxPolygon = bboxPolygon(tmpBbox);

    if (!tmpBboxPolygon.geometry?.coordinates) return;

    const locationBbox = bbox(
      buffer(
        tmpBboxPolygon,
        Math.min(Math.max(2, area(tmpBboxPolygon) / 1_000_000 / 10_000), 500),
        {
          units: 'kilometers',
        }
      )
    );
    return locationBbox;
  }, [data]);

  const computeAssetLocationSource = React.useCallback(() => {
    return AssetLocationSource(data); // with clusters
    // return AssetLocationSource(data, 0, false); // without clusters
  }, [data]);

  React.useEffect(() => {
    if (!data || data.length === 0 || isLoading) return;

    setAssetLocationsDataSource(computeAssetLocationSource());

    setMapBbox(computeBoundingBox());
  }, [data, isLoading]);

  if (!data || isLoading)
    return <Skeleton variant='rectangular' width={'100%'} height={'100%'} />;

  return (
    <Map
      customContainerClass='map-container'
      center={[0, 0]}
      zoom={0}
      minZoom={0}
      sources={[assetLocationsDataSource]}
      layers={layers}
      images={images}
      bounds={mapBbox}
      controlStyles={{
        bottomRight: `display: none;`,
      }}
      mapStyle={storedMapStyle}
    />
  );
};

export default MapChart;
