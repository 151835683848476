import deviceTypes from '../definitions/deviceTypes.json';

export const getDirectionFromDeg = (deg: number) => {
  let direction = '';
  if (deg >= 0 && deg < 11.25) {
    direction = 'N';
  } else if (deg > 11.25 && deg < 33.75) {
    direction = 'NNE';
  } else if (deg > 33.75 && deg < 56.25) {
    direction = 'ENE';
  } else if (deg > 56.25 && deg < 78.75) {
    direction = 'E';
  } else if (deg > 78.75 && deg < 101.25) {
    direction = 'ESE';
  } else if (deg > 101.25 && deg < 123.75) {
    direction = 'ESE';
  } else if (deg > 123.75 && deg < 146.25) {
    direction = 'SE';
  } else if (deg > 146.25 && deg < 168.75) {
    direction = 'SSE';
  } else if (deg > 168.75 && deg < 191.25) {
    direction = 'S';
  } else if (deg > 191.25 && deg < 213.75) {
    direction = 'SSW';
  } else if (deg > 213.75 && deg < 236.25) {
    direction = 'SW';
  } else if (deg > 236.25 && deg < 258.75) {
    direction = 'WSW';
  } else if (deg > 258.75 && deg < 281.25) {
    direction = 'W';
  } else if (deg > 281.25 && deg < 303.75) {
    direction = 'WNW';
  } else if (deg > 303.75 && deg < 326.25) {
    direction = 'NW';
  } else if (deg > 326.25 && deg < 348.75) {
    direction = 'NNW';
  } else if (deg > 348.75 && deg <= 360) {
    direction = 'N';
  }
  return direction;
};

export const convertUnitForAssetType = (
  field: string,
  type: string,
  value: any,
) => {
  if (field === 'speed') {
    if (type === 'vehicle') {
      return value;
    }
    if (['vessel_ais', 'vessel', 'boat'].includes(type)) {
      return (value * 0.539957).toFixed(1);
    }
  }
  if (field === 'distance') {
    if (type === 'vehicle') {
      return value;
    }
    if (['vessel_ais', 'vessel', 'boat'].includes(type)) {
      return (value / 1.852).toFixed(1);
    }
  }
  return value;
};

export const getUnitForAssetType = (field: string, type: string) => {
  if (field === 'speed') {
    if (type === 'vehicle') {
      return 'km/h';
    }
    if (['vessel_ais', 'vessel', 'boat'].includes(type)) {
      return 'knots';
    }
  }
  if (field === 'battery') {
    return '%';
  }
  if (field === 'distance') {
    if (type === 'vehicle') {
      return 'km';
    }
    if (['vessel_ais', 'vessel', 'boat'].includes(type)) {
      return 'NM';
    }
  }
  return '';
};

// Json can not handle serializing bigint data types, this function is a workaround
export function toJson(data: any) {
  return JSON.stringify(data, (_, v) => (typeof v === 'number' ? `${v}` : v)).replace(/"(-?\d+)"/g, (_, a) => a);
}

export function getDeviceFullName(id: any) {
  if (deviceTypes) {
    const config = deviceTypes.deviceTypes.filter((d: any) => d.id === id);
    if (!config || !config[0]) return;
    return `${config?.[0]?.manufacturer} ${config?.[0]?.model}`;
  }
}

export function isLatLong (val: string): boolean {
  const latLong = val.split(',');
  if (latLong.length === 2) {
    if (
      !isNaN(parseFloat(latLong[0])) &&
      !isNaN(parseFloat(latLong[1])) &&
      parseFloat(latLong[0]) >= -90 &&
      parseFloat(latLong[0]) <= 90 &&
      parseFloat(latLong[1]) >= -180 &&
      parseFloat(latLong[1]) <= 180
    ) {
      return true;
    }
  }
  return false;
};
