import { FEUser } from 'common-web/types';
import { apiClient } from 'services/axios';
import { Error, LoginCredentials, RegisterCredentials } from 'types';
import TwoFaResponse from 'types/TwoFaResponse';
import { initReactQueryAuth } from './react-query-auth';

export const loadUser = async () => {
  return (await apiClient.get('/users/current')) as FEUser;
};

const loginFn = async ({
  email,
  password,
  mfaCode,
}: LoginCredentials): Promise<FEUser | TwoFaResponse> => {
  const response: any = await apiClient.post('/login', {
    email,
    password,
    mfaCode,
  });

  if (response.message) {
    return response as TwoFaResponse;
  }

  return response as FEUser;
};

const registerFn = async ({
  name,
  email,
  captcha,
  industry,
  countryCode,
  address,
  phoneNumber,
}: RegisterCredentials): Promise<FEUser> => {
  return (await apiClient.post('/organizations', {
    name,
    email,
    captcha,
    industry,
    countryCode,
    address,
    phoneNumber,
  })) as FEUser;
};

const registerConfirmFn = async ({
  userId,
  registrationId,
  password,
  captcha,
  agreeTermsOfService,
  termsOfServiceVersion,
  settings,
}: any): Promise<FEUser> => {
  return (await apiClient.post(`/register/${userId}`, {
    registrationId,
    password,
    captcha,
    agreeTermsOfService,
    termsOfServiceVersion,
    settings,
  })) as FEUser;
};

const logoutFn = async () => {
  return await apiClient.post('/logout');
};

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  registerConfirmFn,
  logoutFn,
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  FEUser,
  Error,
  LoginCredentials,
  RegisterCredentials
>(authConfig);
