import React from 'react';
import { useTranslation } from 'react-i18next';
import TableToolbar from 'components/TableToolbar';
import Search from 'components/Search';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import { GroupFilter } from 'components/Filters';
import usePermissions from 'context/PermissionContext';

const UsersToolbar = (props: any) => {
  const { t } = useTranslation();
  const { canWrite } = usePermissions();
  const { setSearchTerm, handleAddUser } = props;

  return (
    <TableToolbar grid={[4, 8]}>
      <Search
        translationKey='user_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      {canWrite('USER') && (
        <TableToolbarActionButton
          minWidth={140}
          onClick={handleAddUser}
          translationKey='add_user'
        />
      )}
    </TableToolbar>
  );
};

export default UsersToolbar;
