import React from 'react';
import { useTranslation } from 'react-i18next';
import TableToolbar from 'components/TableToolbar';
import Search from 'components/Search';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import TableToolbarAction from 'components/TableToolbar/TableToolbarAction';
import usePermissions from "../../../context/PermissionContext";

const LocationsToolbar = (props: any) => {
  const { t } = useTranslation();
  const { setSearchTerm, isExporting, handleImportLocation } = props;
  const { canWrite } = usePermissions();

  return (
    <TableToolbar grid={[4, 8]}>
      <Search
        translationKey='location_search.placeholder'
        setSearchTerm={setSearchTerm}
      />
      {canWrite('LOCATION') && (
      <TableToolbarAction>
        <Tooltip arrow={true} title={t('location.upload')}>
          <IconButton disabled={isExporting} onClick={handleImportLocation}>
            {isExporting ? (
              <CircularProgress size={24} sx={{ mr: 0.25 }} />
            ) : (
              <UploadIcon />
            )}
          </IconButton>
        </Tooltip>
      </TableToolbarAction>
      )}
    </TableToolbar>
  );
};

export default LocationsToolbar;
