import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  convertUnitForAssetType,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';
import useActionMenuContext from 'context/ActionMenuContext';
import { useExportReport, useTripReports } from 'hooks/reports/useTrips';
import moment from 'moment';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadIcon from '@mui/icons-material/Download';
import PlaceIcon from '@mui/icons-material/Place';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import MediaDialog from 'components/Reports/VehicleMedia/MediaDialog';
import MediaIcon from 'components/Reports/VehicleMedia/MediaIcon';
import useUIContext from 'context/UIContext';
import TripPlayback from './TripPlayback';
import { PlayArrow } from '@mui/icons-material';
import useKeyPress from 'hooks/useKeyPress';

const getColumns = ({ tripData, t, activeRow, showMediaInDialog }: any) => {
  return [
    {
      id: 'reportType',
      label: 'Type',
      minWidth: 170,
      renderCell: (r: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {activeRow === r.reportId && (
              <PlaceIcon
                sx={{
                  fontSize: '.9rem',
                  mr: 0.5,
                  color: '#FFA500',
                }}></PlaceIcon>
            )}{' '}
            {t(`reports.report_type.${r.reportType}`)}
          </div>
        );
      },
    },
    {
      id: 'timestamp',
      label: 'Reported At',
      minWidth: 170,
      renderCell: (r: any) => {
        return moment(r.timestamp).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      id: 'speed',
      label: 'Speed',
      renderCell: (r: any) => {
        return (
          <>
            {convertUnitForAssetType(
              'speed',
              tripData?.assetType,
              r?.speed || 0
            )}{' '}
            {getUnitForAssetType('speed', tripData?.assetType)}
          </>
        );
      },
    },
    {
      id: 'heading',
      label: 'Heading',
      renderCell: (r: any) => {
        return (
          <>
            {r?.heading || 0}° {`(${getDirectionFromDeg(r?.heading || 0)})`}
          </>
        );
      },
    },
    {
      id: 'transport',
      label: 'Transport',
      renderCell: (r: any) => {
        return t(`reports.transport.${r.transport}`);
      },
    },
    {
      id: 'formattedAddress',
      label: tripData?.assetType === 'vehicle' ? 'Address' : 'Position',
      minWidth: 170,
      renderCell: (r: any) => {
        if (!r?.formattedAddress) {
          return `${r?.position.lat.toFixed(7)}, ${r?.position.lon.toFixed(7)}`;
        }

        return (
          <Tooltip arrow={true} title={r?.formattedAddress}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '.8rem',
                alignItems: 'center',
                mb: 0.5,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {r?.formattedAddress}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      id: 'media',
      label: '',
      align: 'center',
      renderCell: (r: any) => {
        if (r.vehicleMedia) {
          return (
            <MediaIcon
              callback={() => {
                showMediaInDialog(r);
              }}
            />
          );
        }
      },
    },
  ];
};

const TripReports = ({
  tripId,
  tripData,
  onRowClicked,
  playbackOpen,
  setPlaybackOpen,
}: any) => {
  const { t } = useTranslation();
  const { setFlashMessage, isMobile } = useUIContext();
  const escKeyPressed = useKeyPress('Escape');
  const [includeAllReportTypes, setIncludeAllReportTypes] =
    React.useState(false);
  const { data, fetchNextPage, isFetchingNextPage } = useTripReports(
    tripId,
    includeAllReportTypes
  ) as any;
  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportReport();
  const { reset } = useActionMenuContext();
  const scrollRef: any = useRef(null);
  const tableEl: any = useRef();
  const [activeRow, setActiveRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore] = useState(true);
  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [data]);
  const [assetToShowInDialog, showMediaInDialog] = React.useState(null);

  const scrollListener = useCallback(() => {
    let bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }
    if (
      tableEl.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !isFetchingNextPage
    ) {
      loadMore();
    }
  }, [hasMore, loadMore, isFetchingNextPage, distanceBottom]);

  React.useEffect(() => {
    if (escKeyPressed && playbackOpen) {
      console.log('PRESSED');
      setPlaybackOpen(false);
    }
  }, [escKeyPressed, playbackOpen]);

  useLayoutEffect(() => {
    const tableRef: any = tableEl.current;
    tableRef?.addEventListener('scroll', scrollListener);
    return () => {
      tableRef?.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  React.useEffect(() => {
    exportReset();
    return () => reset();
  }, []);

  React.useEffect(() => {
    if (tripData?.deviceType === 'ais_transceiver') {
      setIncludeAllReportTypes(true);
    }
  }, [tripData]);

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeAllReportTypes(!event.target.checked);
  };

  const handleExport = (fileType: any) => {
    exportReport({
      tripId,
      includeAllReportTypes,
      fileType,
    });
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [data, activeRow]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setActiveRow(null);
    }
    if (event.key === 'ArrowUp') {
      handleKeyboardNav('prev');
    }
    if (event.key === 'ArrowDown') {
      handleKeyboardNav('next');
    }
  };

  const handleKeyboardNav = (direction: 'next' | 'prev') => {
    const allReports = data.pages.flatMap((page: any) => page.reports);

    const currentIndex = allReports.findIndex(
      (report: any) => report.reportId === activeRow
    );

    if (currentIndex === -1) return;

    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    if (newIndex < 0 || newIndex >= allReports.length) return;

    const newReport = allReports[newIndex];
    onRowClicked(newReport);
    setActiveRow(newReport.reportId);
  };

  const useStyles = makeStyles({
    customTable: {
      '& .MuiTableBody-root .MuiTableCell-root': {
        padding: '8px 16px', // <-- arbitrary value
      },
    },
  });

  const classes = useStyles();

  return (
    <Box>
      <MediaDialog asset={assetToShowInDialog} showMedia={showMediaInDialog} />
      {data?.pages && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            py: 1,
            px: 1,
            pr: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!includeAllReportTypes}
                  size='small'
                  color='secondary'
                  onChange={handleChange}
                />
              }
              label={
                <Typography sx={{ fontSize: '.8rem', ml: 1 }}>
                  {t('reports.show_events_only')}
                </Typography>
              }
            />
          </FormGroup>
          <Box alignItems={'flex-end'}>
            {!isMobile && (
              <Tooltip arrow={true} title={t('Trip playback')}>
                <IconButton onClick={() => setPlaybackOpen(true)}>
                  <PlayArrow />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow={true} title={t('reports.export_report')}>
              <IconButton disabled={isExporting} onClick={handleClick}>
                {isExporting ? (
                  <CircularProgress size={24} sx={{ mr: 0.25 }} />
                ) : (
                  <DownloadIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleExport('excel')}>
                  <ListItemText
                    primary='.xlsx'
                    primaryTypographyProps={{ fontSize: '.85rem' }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleExport('pdf')}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '.85rem' }}
                    primary='.pdf'
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
          {exportSuccess && exportData && exportData.reportUrl && (
            <iframe
              width='1pxre'
              height='1px'
              style={{ visibility: 'hidden', position: 'absolute' }}
              referrerPolicy='origin'
              src={exportData.reportUrl}></iframe>
          )}
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '4px',
          border: '1px solid rgba(81, 81, 81, 1)',
        }}>
        {!data?.pages &&
          [...Array(10)].map(i => (
            <Skeleton
              key={i}
              variant='rectangular'
              width={'100%'}
              height={30}
              sx={{ mb: 3 }}
              animation='wave'
            />
          ))}
        <TableContainer sx={{ maxHeight: '50vh' }} ref={tableEl}>
          <Box sx={{ overflowX: 'hidden' }}>
            <Table
              stickyHeader
              aria-label='sticky table'
              classes={{ root: classes.customTable }}>
              <TableHead>
                <TableRow>
                  {getColumns({ tripData, t }).map((column: any) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, lineHeight: 0.5 }}
                      sx={{ backgroundColor: '#1D1F20', fontWeight: 'bold' }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.pages.map((group: any, i: any) => (
                  <React.Fragment key={i}>
                    {group?.reports?.map((row: any) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.code}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (row.reportId === activeRow) {
                            onRowClicked(null);
                            setActiveRow(null);
                          } else {
                            onRowClicked(row);
                            setActiveRow(row.reportId);
                          }
                        }}>
                        {getColumns({
                          tripData,
                          t,
                          activeRow,
                          showMediaInDialog,
                        }).map((column: any) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.renderCell
                                ? column.renderCell(row)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                <Box key='table_scroll_ref' ref={scrollRef} />
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        <Box
          sx={{
            p: 2,
            textAlign: 'right',
            fontSize: '.8rem',
            borderTop: '1px solid rgb(81, 81, 81)',
          }}>
          {data?.pages[0].numRecords || 0} reports
        </Box>
      </Box>
      <TripPlayback
        tripId={tripId}
        open={playbackOpen}
        onClose={() => setPlaybackOpen(false)}
      />
    </Box>
  );
};

export default TripReports;
