import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { Delete, Replay } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Lock from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, IconButton, Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  useDeleteUser,
  useResendActivationEmail,
  useUsers,
} from 'hooks/users/useUsers';
import { t } from 'i18next';
import UsersToolbar from './UsersToolbar';
import ConfirmDialog from 'components/SystemDialogs/ConfirmDialog';
import moment from 'moment';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

function getColumns({
  handleEditUser,
  handleDeleteUser,
  handleResendActivationEmailDialog,
  isDeleting,
  canWrite,
}: any): GridColDef[] {
  return [
    //@ts-ignore
    {
      field: 'name',
      headerName: 'Name',
      ...commonColDefOptions,
      flex: 2,
      renderCell: (r: any) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ fontWeight: 600 }}>{r.row.name}</Box>
            <Box>{r.row.email}</Box>
          </Box>
        );
      },
    }, //@ts-ignore
    {
      field: 'status',
      headerName: 'Status',
      ...commonColDefOptions,
      renderCell: (r: any) => {
        let cell = [r?.row?.status];
        if ('unregistered' === r?.row?.status && canWrite('USER')) {
          const canResend =
            !r?.row?.lastInvitedAt ||
            moment(r?.row?.lastInvitedAt).isBefore(
              moment().subtract('10', 'minutes')
            );
          cell.push(
            <Tooltip
              title={
                !canResend
                  ? 'Please wait some time before resending the activation email.'
                  : 'Resend activation email'
              }>
              <span>
                <IconButton
                  onClick={() =>
                    canResend ? handleResendActivationEmailDialog(r?.row) : null
                  }
                  disabled={!canResend}
                  sx={{
                    marginLeft: 1,
                  }}>
                  <Replay fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          );
        }
        return cell;
      },
    }, //@ts-ignore
    {
      field: 'group',
      headerName: 'Group',
      ...commonColDefOptions,
      valueGetter: ({ row }) => row?.group?.name,
    }, //@ts-ignore
    {
      field: 'role',
      headerName: 'Role',
      ...commonColDefOptions,
      valueGetter: ({ row }) => (row?.isOwner ? 'Owner' : row?.role?.name),
    }, //@ts-ignore
    {
      field: 'mfaEnabled',
      headerName: '2FA',
      ...commonColDefOptions,
      minWidth: 40,
      maxWidth: 60,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (r: any) => {
        let icon = r?.value ? (
          <Lock fontSize='small' />
        ) : (
          <LockOpenIcon fontSize='small' />
        );
        let label = t(r?.value ? 'user_2fa_enabled' : 'user_2fa_disabled');
        return (
          <Box sx={{ color: r?.value ? 'success.light' : 'text.disabled' }}>
            <Tooltip title={label}>{icon}</Tooltip>
          </Box>
        );
      },
    }, //@ts-ignore
    ...(canWrite('USER')
      ? [
          {
            headerName: '',
            ...commonColDefOptions,
            minWidth: 90,
            maxWidth: 100,
            align: 'center',
            flex: 0.5,
            renderCell: (r: any) => {
              return (
                <>
                  <Tooltip title='Edit user'>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleEditUser(r?.row?.userId)}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete user'>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleDeleteUser(r?.row)}>
                      <Delete fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];
}

const Users = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const { data, isLoading } = useUsers({ searchTerm, page, pageSize }) as any;
  const { mutate, isLoading: isDeleting } = useDeleteUser();
  const {
    mutate: resendInvite,
    data: resendInviteResponse,
    error: resendInviteErrorResponse,
    isLoading: isResendingInvite,
    isError: resendInviteError,
  } = useResendActivationEmail();
  const { setActive, reset } = useActionMenuContext();
  const { canWrite } = usePermissions();
  const { openRightSidebar, setFlashMessage } = useUIContext();
  const [deleteDialog, setDeleteDialog] = useState<any>(null);
  const [resendActivationEmailDialog, setResendActivationEmailDialog] =
    useState<any>(null);

  React.useEffect(() => {
    return () => reset();
  }, []);

  const handleAddUser = () => {
    setActive(ActionMenuModuleTypes.AddUser);
    openRightSidebar();
  };

  const handleDeleteUser = (user: any) => {
    setDeleteDialog(user);
  };

  const handleEditUser = (id: number) => {
    setActive(ActionMenuModuleTypes.EditUser, { userId: id });
    openRightSidebar();
  };

  const handleResendActivationEmailDialog = (user: any) => {
    setResendActivationEmailDialog(user);
  };

  useEffect(() => {
    if (isResendingInvite) return;
    if (!resendInviteError) {
      setFlashMessage({
        message: t(resendInviteResponse?.message),
      });
      setResendActivationEmailDialog(null);
    } else {
      setFlashMessage({
        message: t(resendInviteErrorResponse?.response?.data?.message),
        severity: 'error',
      });
    }
  }, [
    resendInviteError,
    isResendingInvite,
    resendInviteResponse,
    resendInviteErrorResponse,
  ]);

  return (
    <BoxedContainer>
      <UsersToolbar
        setSearchTerm={setSearchTerm}
        handleAddUser={handleAddUser}
      />
      <Table
        data={data}
        isLoading={isLoading}
        getRowId={(row: any) => row.userId}
        columns={getColumns({
          handleEditUser,
          handleDeleteUser,
          handleResendActivationEmailDialog,
          isDeleting,
          canWrite,
        })}
        pageSize={pageSize}
        onPageChange={(page: number) => setPage(page + 1)}
        paginationMode={'client'}
        rowHeight={52}
      />
      <ConfirmDialog
        open={!!resendActivationEmailDialog}
        onCancel={() => {
          setResendActivationEmailDialog(null);
        }}
        onConfirm={() => {
          if (resendActivationEmailDialog)
            resendInvite({ userUuid: resendActivationEmailDialog.uuid });
        }}
        title={'Resend Activation Email'}
        content={
          <>
            Do you want to resend the activation email for{' '}
            <b>{resendActivationEmailDialog?.email}</b>?
          </>
        }
      />
      <ConfirmDeleteDialog
        open={!!deleteDialog}
        onCancel={() => {
          setDeleteDialog(null);
        }}
        onConfirm={() => {
          if (deleteDialog) mutate({ id: deleteDialog?.userId });
        }}
        title={'Confirm to delete asset'}
        content={
          <>
            Are you sure you want to delete <b>{deleteDialog?.name}</b>?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Users;
