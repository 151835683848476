import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { apiClient } from 'services/axios';
import { Location } from 'types';

export function useLocation(variables?: { id: number | null }): UseQueryResult {
  return useQuery(
    ['location', variables],
    () => apiClient.get(`locations/${variables?.id}`),
    {
      enabled: !!variables?.id,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export function useLocationDetails(variables?: {
  location: number | null;
}): UseQueryResult {
  return useQuery(
    ['location', variables],
    () => apiClient.get(`locations/${variables?.location}`),
    {
      enabled: !!variables?.location,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export function useLocations(variables?: {
  searchTerm?: string;
  page?: number;
  pageSize?: number;
}): UseQueryResult {
  return useQuery(['locations', variables], ({ signal }) =>
    apiClient.get('locations', {
      signal,
      params: variables,
    })
  );
}

export function useLocationsById(locationIds: number[]): UseQueryResult {
  return useQuery(['locations', locationIds], ({ signal }) =>
    apiClient.get('locations', {
      signal,
      params: { locationIds },
    })
  );
}

export function useAddLocation(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'locations',
    (variables: Location) =>
      apiClient.post('locations', {
        name: variables.name,
        description: variables.description,
        groupId: variables.groupId,
        position: {
          lon: variables.position.lon,
          lat: variables.position.lat,
        },
        bounds: variables.bounds,
        entryAlert: variables.entryAlert,
        exitAlert: variables.exitAlert,
        speedingThreshold: variables.speedingThreshold,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['locations'] });
      },
    }
  );
}

export function useEditLocation(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'locations',
    (variables: Location) =>
      apiClient.put(`locations/${variables?.locationId}`, {
        name: variables.name,
        description: variables.description,
        feature: variables.feature,
        groupId: variables.groupId,
        position: {
          lon: variables.position.lon,
          lat: variables.position.lat,
        },
        bounds: variables.bounds,
        entryAlert: variables.entryAlert,
        exitAlert: variables.exitAlert,
        speedingThreshold: variables.speedingThreshold,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
      },
    }
  );
}

export function useImportLocations(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'import_locations',
    async (variables: any) => {
      let data = new FormData();
      data.append('file', variables.file);
      return apiClient.post<any>('locations/import', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('import_locations');
        queryClient.invalidateQueries('locations');
      },
    }
  );
}

export function useDeleteLocation(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'locations',
    (variables: { id: number }) =>
      apiClient.delete(`locations/${variables.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
      },
    }
  );
}
